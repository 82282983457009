import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <nav>
      <div className="container">
        <div className="nav-columns">
          <div className="nav-column">
            <div className="nav-label">
              <ul className="nav-links">
                <li>
                  <NavLink to="/case-studies" exact>
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/services" exact>
                    Services
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about-us" exact>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="nav-column">
            <div className="nav-label">
              <div className="nav-infos">
                <ul className="nav-info">
                  <li>
                    <NavLink to="/contact" exact>
                      <b>Get in touch with us</b>
                    </NavLink>
                  </li>
                  <li className="nav-info-label">
                    <b>Phone</b>
                  </li>
                  <li>(909) 244-0220</li>
                  <li className="nav-info-label">
                    <b>Email</b>
                  </li>
                  <li className="nav-info-label">
                    contact@reycoengineering.com
                  </li>
                </ul>
                <ul className="nav-info">
                  <li className="nav-info-label">
                    <b>Office</b>
                  </li>
                  <li>6371 Haven Ave </li>
                  <li>STE 3306</li>
                  <li>Rancho Cucamonga, CA</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
