import React from "react";
import { ReactComponent as RightArrow } from "../assets/arrow-right.svg";
import About from "./About";
// import reyco1 from "../assets/reyco1.jpg";
import gsap from "gsap";
const Banner = () => {
  return (
    <>
      <section className="main">
        <div className="banner-background">
          <div className="container">
            <div className="row">
              <h2 className="banner-line">
                <div className="line">
                  <span>Doing whatever it takes.</span>
                </div>
              </h2>
              {/* <div className="btn-row">
              <a href="/">
                See More About Us <RightArrow />
              </a>
            </div> */}
              <div className="btn-row-bottom">
                <ul>
                  <li>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        gsap.to(window, {
                          duration: 1,
                          scrollTo: { y: "#about-section", offsetY: 100 },
                        });
                      }}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        gsap.to(window, {
                          duration: 1,
                          scrollTo: { y: "#service-section", offsetY: 100 },
                        });
                      }}
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        gsap.to(window, {
                          duration: 1,
                          scrollTo: { y: "#contact-section" },
                        });
                      }}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <About /> */}
    </>
  );
};

export default Banner;
