import React from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const Services = () => {
  ScrollTrigger.create({
    trigger: "#service-section",
    once: true,
    onEnter: function () {
      gsap.fromTo(
        ".services-container",
        {
          autoAlpha: 0,
          y: -50,
        },
        { duration: 2, y: 0, autoAlpha: 1, ease: "expo", overwrite: "auto" }
      );
    },
  });
  return (
    <section className="services" id="service-section">
      <div className="container">
        <div className="row">
          <div className="services-container">
            <div className="heading">
              <div className="phrase">
                <h1>Delivering quality services for over three decades.</h1>
              </div>
            </div>
            <div className="services-wrapper">
              <div className="service">
                <span className="service-label">
                  <h3>Services</h3>
                </span>
                <ul className="service-info">
                  <li className="service-info-item">Demolition</li>
                  <li className="service-info-item">Earthwork</li>
                  <li className="service-info-item">Paving</li>
                  <li className="service-info-item">Underground Utilities</li>
                  <li className="service-info-item">Retaining Walls</li>
                  <li className="service-info-item">Design-Build</li>
                  <li className="service-info-item">Structures</li>
                  <li className="service-info-item">Building Renovations</li>
                  <li className="service-info-item">Seismic Retrofit</li>
                  <li className="service-info-item">Steel Stud Framing</li>
                </ul>
              </div>
              <div className="service">
                <span className="service-label">
                  <h3>NAICS Codes</h3>
                </span>
                <ul className="service-info">
                  <li className="service-info-item">221310</li>
                  <li className="service-info-item">236210</li>
                  <li className="service-info-item">236220</li>
                  <li className="service-info-item">237110</li>
                  <li className="service-info-item">237310</li>
                  <li className="service-info-item">237990</li>
                  <li className="service-info-item">238220</li>
                  <li className="service-info-item">238910</li>
                  <li className="service-info-item">238990</li>
                  <li className="service-info-item">561210</li>
                  <li className="service-info-item">561730</li>
                  <li className="service-info-item">562910</li>
                </ul>
              </div>
              <div className="service">
                <span className="service-label">
                  <h3>Certifications</h3>
                </span>
                <ul className="service-info">
                  <li className="service-info-item">
                    Minority Business Enterprise(MBE)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
