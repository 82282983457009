import React from "react";
// import reyco1 from "../assets/reyco1.jpg";
// import reyco2 from "../assets/reyco2.jpg";
import streetview from "../assets/streetview.jpg";
import reycoconcrete from "../assets/reycoconcrete.jpg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
// import topview from "../assets/topview.jpg";

const About = () => {
  ScrollTrigger.create({
    trigger: "#about-section",
    once: true,
    onEnter: function () {
      gsap.fromTo(
        ".about-img",
        { autoAlpha: 0 },
        { duration: 2, autoAlpha: 1 }
      );
      gsap.fromTo(
        "#para-con",
        { x: 50, autoAlpha: 0 },
        { duration: 2, x: 0, autoAlpha: 1, ease: "expo", overwrite: "auto" }
      );
    },
  });
  return (
    <section className="about" id="about-section">
      <div className="container">
        <div className="row">
          <div className="about-content">
            <div className="about-content-img">
              <img src={reycoconcrete} alt="" className="about-img" />
            </div>
            <div className="paragraph">
              <div className="paragraph-content" id="para-con">
                <h2>A bit about us...</h2>
                <p>
                  Reyco Engineering Inc., is a family owned and locally operated
                  General Contractor with its operational headquarters located
                  in Rancho Cucamonga, California. Our license classifications
                  in both general Engineering(A) and General Building(B) has
                  enabled total project delivery services in heavy civil
                  construction, general building construction, design-build,
                  repairs, and renovations. We pride ourselves with over three
                  decades of experience in the construction industry that has
                  yielded high quality results, all accomplished in a safe,
                  timely, and cost-effective manner. By embracing <i>SAFETY</i>{" "}
                  and <i>QUALITY</i> as our guiding principles, we provide
                  customized construction, design-build and related construction
                  services all while developing long-term relationships and
                  added value to our customers in both the private and public
                  sector.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
