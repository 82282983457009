import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const ContactUs = () => {
  const [contact, setContact] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const [sent, setSent] = useState(false);

  ScrollTrigger.create({
    trigger: "#contact-section",
    once: true,
    onEnter: function () {
      gsap.fromTo(
        ".contact-us-container",
        { autoAlpha: 0, x: -50 },
        {
          duration: 2,
          x: 0,
          autoAlpha: 1,
          ease: "expo",
          overwrite: "auto",
        }
      );
    },
  });

  const handleSubmit = (values, { resetForm }) => {
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        values,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then((res) => {
        setSent(true);
        resetForm();
      })
      .catch((err) => console.warn(err));
  };

  return (
    <section className="contact" id="contact-section">
      <div className="container">
        <div className="row">
          <div className="contact-us-container">
            <div className="contact-content-left">
              {/* <div className="contact-message"> */}
              <h1 className="contact-text">We'd Love to Hear From You</h1>
              <p>
                Whatever it may be, we are happy to answer all of your
                questions! Either fill out the form with any questions or
                inqueries, or call us at (909) 244-0220.
              </p>
              {/* </div> */}
            </div>
            <div className="contact-content-right">
              <div className="contact-content">
                <h2 className="contact-logo">Reyco Engineering, Inc.</h2>
                <p className="contact-item">
                  6371 Haven Ave
                  <br />
                  STE 3306
                  <br />
                  Rancho Cucamonga, CA
                </p>
                <p className="contact-item">(909) 244-0220</p>
                <p className="contact-item">admin@reycoengineering.com</p>
                <div className="contact-form">
                  <Formik
                    enableReinitialize={true}
                    initialValues={contact}
                    validationSchema={Yup.object().shape({
                      user_name: Yup.string().required(
                        "Please enter your name here"
                      ),
                      user_email: Yup.string()
                        .email()
                        .required("A valid email is required"),
                      message: Yup.string().required(
                        "Please enter your message here"
                      ),
                    })}
                    onSubmit={handleSubmit}
                  >
                    {(props) => {
                      const { values, touched, errors, handleSubmit, isValid } =
                        props;
                      return (
                        <Form onSubmit={handleSubmit}>
                          <div className="input-container">
                            <label htmlFor="name" className="form-label">
                              <b>Name</b>
                            </label>
                            <Field
                              type="text"
                              name="user_name"
                              value={values.user_name}
                              className="contact-input"
                            />
                            {errors.user_name && touched.user_name ? (
                              <div className="errors">{errors.user_name}</div>
                            ) : null}
                          </div>
                          <div className="input-container">
                            <label htmlFor="email" className="form-label">
                              <b>Email</b>
                            </label>
                            <Field
                              type="text"
                              name="user_email"
                              value={values.user_email}
                              className="contact-input"
                            />
                            {errors.user_email && touched.user_email ? (
                              <div className="errors">{errors.user_email}</div>
                            ) : null}
                          </div>
                          <div className="input-container">
                            <label htmlFor="message" className="form-label">
                              <b>Message</b>
                            </label>
                            <Field
                              as="textarea"
                              name="message"
                              value={values.message}
                              className="contact-input"
                            />
                          </div>
                          {errors.message && touched.message ? (
                            <div className="errors">{errors.message}</div>
                          ) : null}
                          <div className="input-container">
                            <button
                              disabled={!isValid}
                              className="input-button"
                              type="submit"
                            >
                              Send!
                            </button>
                            {sent && (
                              <p>Thank you, we will get back to you shortly!</p>
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
