import React, { useState, useEffect } from "react";
import gsap from "gsap";
import IntroOverlay from "../componenets/IntroOverlay";
import Banner from "../componenets/Banner";
import About from "../componenets/About";
import ContactUs from "../componenets/ContactUs";
import Services from "../componenets/Services";

const tl = gsap.timeline();

const homeAnimation = (completeAnimation) => {
  tl.from(".line span", {
    duration: 1.8,
    y: -75,
    ease: "power4.out",
    delay: 1,
    skewY: 7,
    stagger: { amount: 0.5 },
  })
    .to(".top", {
      duration: 1.8,
      delay: 0,
      y: "-100%",
      ease: "expo.inOut",
    })
    .to(
      ".logo",
      {
        duration: 1.8,
        css: { opacity: "1" },
        ease: "expo.inOut",
        delay: 1,
        stagger: {
          amount: 0.4,
        },
      },
      "-=1.8"
    )
    .to(".intro-overlay", {
      delay: 0,
      css: { display: "none" },
      onComplete: completeAnimation,
    });
};

const Home = (props) => {
  let [animationComplete, setAnimationComplete] = useState(false);

  const completeAnimation = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    homeAnimation(completeAnimation);
  }, []);
  return (
    <>
      {animationComplete === false ? <IntroOverlay /> : ""}
      <Banner />
      <About />
      <Services />
      <ContactUs />
    </>
  );
};

export default Home;
