import React from "react";
import { NavLink, withRouter } from "react-router-dom";

const Header = ({ history, dimensions }) => {
  return (
    <div className="header">
      <div className="container">
        <div className="row v-center space-between">
          <div className="logo">
            <NavLink to="/">Reyco Engineering, Inc.</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
