import React, { useState, useEffect } from "react";
import gsap from "gsap";
import "./styles/App.scss";
import Header from "./componenets/Header";
import Home from "./pages/Home";
import { Route } from "react-router-dom";
import Navigation from "./componenets/Navigation";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const routes = [
  {
    path: "/",
    name: "Home",
    Component: Home,
  },
];

function App() {
  gsap.to("body", { duration: 0, css: { visibility: "visible" } });
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  // useEffect(() => {
  //   let vh = dimensions.height * 0.01;

  //   document.documentElement.style.setProperty("--vh", `${vh}px`);
  //   const debouncedHandleResize = debounce(function hanldeResize() {
  //     setDimensions({ height: window.innerHeight, width: window.innerWidth });
  //   }, 1000);

  //   window.addEventListener("resize", debouncedHandleResize);

  //   return () => {
  //     window.removeEventListener("resize", debouncedHandleResize);
  //   };
  // }, [dimensions.height]);
  return (
    <>
      {/* <Header dimensions={dimensions} /> */}
      <Header />

      <div className="App">
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            {/* <Component dimensions={dimensions} />
             */}
            <Component />
          </Route>
        ))}
      </div>
      <Navigation />
    </>
  );
}

export default App;
